import React, { ReactElement } from 'react';
import './styles.scss';

import {AnimationOnScroll} from 'react-animation-on-scroll';
import portfolioItems from '../../../text/portfolio.json';
import { IPortfolioItem } from '../../../types/text';
import PortfolioItem from './components/PortfolioItem';

const PortfolioSection = (): ReactElement => (
    <section className="PortfolioSection content-section bg-primary-dark text-white text-center" id="portfolio">
        <div className="container">
            <AnimationOnScroll className="content-section-heading" animateOnce animateIn="animate__bounceInLeft">
                <h2 className="mb-5">Portfolio</h2>
            </AnimationOnScroll>

            <div className="portfolio-gallery">
                {portfolioItems.map((item: IPortfolioItem) => (
                    <PortfolioItem key={item.title} item={item} />
                ))}
            </div>
        </div>
    </section>
);

export default PortfolioSection;
