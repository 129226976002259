import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import Application from './app';
import * as serviceWorker from './serviceWorker';

import './styles/main.scss';

ReactGA.initialize('UA-143789875-1');
ReactGA.ga('set', 'anonymizeIp', true);

ReactDOM.render(
    <React.StrictMode>
        <Application />
    </React.StrictMode>,
    document.getElementById('root'),
);

serviceWorker.unregister();
