import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import { ITimelineItem } from '../../../../../types/text';

import './styles.scss';

export interface TimelineItemProps {
    side: 'left' | 'right';
    item: ITimelineItem;
    end?: boolean;
    start?: boolean;
}

const TimelineItem = (props: TimelineItemProps): ReactElement => {
    const {
        side, item, end = true, start = true,
    } = props;

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ];

    const date = new Date(item.timestamp * 1000);

    const content = (
        <AnimationOnScroll animateOnce className="card shadow" animateIn={side === 'left' ? 'animate__fadeInLeft' : 'animate__fadeInRight'}>
            <div className="card-body">
                <div className="float-right small">
                    {monthNames[date.getMonth()]}
                    {' '}
                    {date.getFullYear()}
                </div>
                <h4 className="card-title">{item.title}</h4>
                <p className="card-text">
                    <ReactMarkdown>
                        {item.text}
                    </ReactMarkdown>
                </p>
            </div>
        </AnimationOnScroll>
    );

    return (
        <div className="row no-gutters">
            <div className="col-sm py-2">
                {side === 'left' && content}
            </div>
            <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                <div className="row h-50">
                    {start && (
                        <div className="col border-right">&nbsp;</div>
                    )}
                    <div className="col">&nbsp;</div>
                </div>
                <h5 className="m-2">
                    <span className="badge badge-pill border">&nbsp;</span>
                </h5>
                <div className="row h-50">
                    {end && (
                        <div className="col border-right">&nbsp;</div>
                    )}
                    <div className="col">&nbsp;</div>
                </div>
            </div>
            <div className="col-sm py-2">
                {side === 'right' && content}
            </div>
        </div>
    );
};

export default TimelineItem;
