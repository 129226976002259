import React, { ReactElement } from 'react';

import './styles.scss';
import Masthead from './sections/Masthead';
import Navigation from './sections/Navigation';
import AboutSection from './sections/About';
import PortfolioSection from './sections/Portfolio';
import TimelineSection from './sections/Timeline';
import ContactSection from './sections/Contact';
import Footer from './sections/Footer';

const Application = (): ReactElement => (
    <>
        <Masthead />
        <Navigation />
        <div>
            <AboutSection />
            <PortfolioSection />
            <TimelineSection />
            <ContactSection />
        </div>
        <Footer />
    </>
);

export default Application;
