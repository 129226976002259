import React, { ReactElement, useState } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-scroll';

const Footer = (): ReactElement => {
    const [imprint, setImprint] = useState(false);

    return (
        <>
            <footer className="Footer text-center">
                <Link smooth to="home" className="up-arrow">
                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                </Link>

                <div className="container">
                    <ul className="list-inline mb-5">
                        <li className="list-inline-item">
                            <a
                                className="social-link rounded-circle text-white mx-2"
                                rel="noopener noreferrer"
                                href="https://www.linkedin.com/in/fabian-emilius-228028185/"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                className="social-link rounded-circle text-white mx-2"
                                rel="noopener noreferrer"
                                href="https://github.com/fabian-emilius"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                        </li>
                    </ul>
                    <p className="text-muted small mb-0">
                        <span>Fabian Emilius</span>
                        <span className="text-highlight ml-1">
                            &copy;
                            {new Date().getFullYear()}
                        </span>
                        <span>-</span>
                        <button type="button" className="imprint-button" onClick={(): void => setImprint(true)}>Imprint</button>
                    </p>
                </div>
            </footer>
            <Modal show={imprint} onHide={(): void => setImprint(false)}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Imprint</h5>
                        <button type="button" className="close" onClick={(): void => setImprint(false)}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        Name: Fabian Emilius
                        <br />
                        Email: fabian.emilius@gmail.com
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Footer;
