import React, { ReactElement } from 'react';

export interface TimelineYearProps {
    year: number;
    start?: boolean;
    end?: boolean;
}

const TimelineYear = (props: TimelineYearProps): ReactElement => {
    const { year, start = true, end = true } = props;

    return (
        <div className="row no-gutters">
            <div className="col-sm" />
            <div className="col-sm-1 text-center flex-column">
                <div className="row">
                    {start && (
                        <div className="col border-right">&nbsp;</div>
                    )}
                    <div className="col">&nbsp;</div>
                </div>
                <h5 className="m-2">
                    <span className="badge badge-pill border timeline-year">{year}</span>
                </h5>
                <div className="row">
                    {end && (
                        <div className="col border-right">&nbsp;</div>
                    )}
                    <div className="col">&nbsp;</div>
                </div>
            </div>
            <div className="col-sm" />
        </div>
    );
};

export default TimelineYear;
