import React, { ReactElement } from 'react';
import {AnimationOnScroll} from 'react-animation-on-scroll';

import timelineItems from '../../../text/timeline.json';
import { ITimelineItem } from '../../../types/text';
import TimelineItem from './components/TimelineItem';
import TimelineYear from './components/TimelineYear';

import './styles.scss';

const TimelineSection = (): ReactElement => {
    const items: ITimelineItem[] = timelineItems
        .sort((a: ITimelineItem, b: ITimelineItem) => b.timestamp - a.timestamp);

    let year = 0;

    return (
        <section className="TimelineSection content-section bg-primary text-white text-center" id="timeline">
            <div className="container">
                <AnimationOnScroll className="content-section-heading" animateOnce animateIn="animate__bounceInLeft">
                    <h2 className="mb-5">Timeline</h2>
                </AnimationOnScroll>

                {items.map((item, index) => {
                    const elemYear = new Date(item.timestamp * 1000).getFullYear();
                    let spacer = null;

                    if (elemYear < year) {
                        spacer = (
                            <TimelineYear year={year} />
                        );
                    }

                    year = elemYear;

                    return (
                        <span key={item.title}>
                            {spacer}
                            <TimelineItem start={index > 0} side={index % 2 === 0 ? 'left' : 'right'} item={item} />
                        </span>
                    );
                })}
                <TimelineYear year={year} end={false} />
            </div>
        </section>
    );
};

export default TimelineSection;
