import React, { ReactElement } from 'react';
import './styles.scss';

const Masthead = () : ReactElement => (
    <header className="Masthead d-flex" id="home">
        <div className="container my-auto">
            <h3 className="main-text mb-5">
                Hello, I&apos;m
                {' '}
                <span className="text-highlight">Fabian Emilius</span>
                <br />
                I&apos;m a product focused founder.
            </h3>
        </div>
    </header>
);

export default Masthead;
