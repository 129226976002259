import React, { ReactElement, useState } from 'react';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import { IPortfolioItem } from '../../../../../types/text';
import PortfolioModal from '../PortfolioModal';

interface PortfolioItemProps {
    item: IPortfolioItem;
}

const PortfolioItem = (props: PortfolioItemProps): ReactElement => {
    const { item } = props;

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <AnimationOnScroll className="portfolio-item" animateOnce animateIn="animate__flipInX">
                <div className="portfolio-item-image" style={{ backgroundImage: `url('${item.thumbnail}')` }} />
                <div className="portfolio-item-text">
                    <div className="portfolio-item-title">{item.title}</div>
                    <div className="portfolio-item-caption">{item.caption}</div>
                    <button type="button" className="portfolio-item-button portfolio-gallery-button" onClick={(): void => setShowModal(true)}>
                        LEARN MORE
                    </button>
                </div>
            </AnimationOnScroll>
            <PortfolioModal show={showModal} onHide={(): void => setShowModal(false)} item={item} />
        </>
    );
};

export default PortfolioItem;
