import React, { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';

import './styles.scss';

const Navigation = (): ReactElement => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="Navigation">
            <nav>
                <div className="container">
                    <ul className={`nav${visible ? ' visible' : ''}`}>
                        <li className="nav-item">
                            <Link className="nav-link" spy smooth to="home" onClick={(): void => setVisible(false)}>home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" spy smooth to="about" onClick={(): void => setVisible(false)}>about</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" spy smooth to="portfolio" onClick={(): void => setVisible(false)}>portfolio</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" spy smooth to="timeline" onClick={(): void => setVisible(false)}>timeline</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" spy smooth to="contact" onClick={(): void => setVisible(false)}>contact</Link>
                        </li>
                    </ul>
                    <button type="button" className="navbar-toggler" onClick={(): void => setVisible((prev) => !prev)}>
                        Menu
                        {' '}
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
            </nav>
        </div>
    );
};

export default Navigation;
