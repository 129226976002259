import React, { ReactElement } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDatabase, faProjectDiagram, faRobot, faServer,
} from '@fortawesome/free-solid-svg-icons';
import {AnimationOnScroll} from 'react-animation-on-scroll';

const AboutSection = (): ReactElement => (
    <section className="AboutSection content-section bg-primary text-white text-center" id="about">
        <div className="container mb-5">
            <AnimationOnScroll className="content-section-heading" animateOnce animateIn="animate__bounceInLeft">
                <h2 className="mb-5">About</h2>
            </AnimationOnScroll>
            <div className="row">
                <AnimationOnScroll className="col-lg-3 col-md-6 mb-5 mb-lg-0" animateOnce animateIn="animate__flipInY">
                    <span className="service-icon rounded-circle mx-auto mb-3">
                        <FontAwesomeIcon icon={faProjectDiagram} />
                    </span>
                    <h4 className="font-weight-bold">Product</h4>
                </AnimationOnScroll>
                <AnimationOnScroll className="col-lg-3 col-md-6 mb-5 mb-lg-0" animateOnce animateIn="animate__flipInY" delay={150}>
                    <span className="service-icon rounded-circle mx-auto mb-3">
                        <FontAwesomeIcon icon={faDatabase} />
                    </span>
                    <h4 className="font-weight-bold">Fullstack</h4>
                </AnimationOnScroll>
                <AnimationOnScroll className="col-lg-3 col-md-6 mb-5 mb-lg-0" animateOnce animateIn="animate__flipInY" delay={300}>
                    <span className="service-icon rounded-circle mx-auto mb-3">
                        <FontAwesomeIcon icon={faServer} />
                    </span>
                    <h4 className="font-weight-bold">Databases</h4>
                </AnimationOnScroll>
                <AnimationOnScroll className="col-lg-3 col-md-6 mb-5 mb-lg-0" animateOnce animateIn="animate__flipInY" delay={450}>
                    <span className="service-icon rounded-circle mx-auto mb-3">
                        <FontAwesomeIcon icon={faRobot} />
                    </span>
                    <h4 className="font-weight-bold">Automation</h4>
                </AnimationOnScroll>
            </div>
        </div>
        <div className="container mt-5">
            <div className="row">
                <AnimationOnScroll className="col-md-6 mb-3" animateOnce animateIn="animate__bounceInLeft">
                    <img src="/images/me.jpg" alt="Fabian Emilius" className="personal-photo" />

                </AnimationOnScroll>
                <AnimationOnScroll className="col-md-6" animateOnce animateIn="animate__bounceInRight">
                    <h5 className="mt-4">Who am I?</h5>
                    <div className="mb-2">
                        My name is Fabian and I am {Math.floor((Date.now() - 908668800000) / (3600 * 24 * 365 * 1000))} years old.
                    </div>
                    <div className="mb-2">
                        I am the Co-Founder of <a href="https://atomichub.io">AtomicHub</a> and <a href="https://pink.gg">pink.gg</a>.
                        I am specialized in designing and building products from scratch and have built up a team of 40 people where I lead 22 people on the product and tech side as CTO.
                    </div>
                    <div className="mb-2">
                        2023 I sold my company that built AtomicHub and started working on SaaS products.
                    </div>
                </AnimationOnScroll>
            </div>
        </div>
    </section>
);

export default AboutSection;
