import React, {
    FormEvent, ReactElement, useRef, useState,
} from 'react';
import './styles.scss';
import {AnimationOnScroll} from 'react-animation-on-scroll';

const ContactSection = (): ReactElement => {
    const nameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const messageRef = useRef<HTMLTextAreaElement>(null);

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const submitForm = (event: FormEvent): void => {
        event.preventDefault();

        fetch('https://formspree.io/f/mgepowol', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: nameRef.current?.value || '',
                email: emailRef.current?.value || '',
                message: messageRef.current?.value || '',
            }),
        }).then((res) => res.json()).then((res) => {
            if (res.ok) {
                setSuccess('You successfully submitted the message!');

                if (nameRef.current) {
                    nameRef.current.value = '';
                }

                if (emailRef.current) {
                    emailRef.current.value = '';
                }

                if (messageRef.current) {
                    messageRef.current.value = '';
                }
            } else {
                throw new Error(res.message);
            }
        }).catch(() => {
            setError('An error occurred while sending the message!');
        });
    };

    return (
        <>
            <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="75" width="100%" version="1.1" className="bg-secondary">
                <path d="M0 0 L50 100 L100 0 Z" fill="#252934" stroke="#252934" />
            </svg>

            <section className="ContactSection content-section bg-secondary text-secondary text-center" id="contact">
                <div className="container">
                    <AnimationOnScroll className="content-section-heading" animateOnce animateIn="animate__bounceInLeft">
                        <h2 className="mb-5">Contact</h2>
                    </AnimationOnScroll>

                    <AnimationOnScroll className="mb-5" animateOnce animateIn="animate__bounceInLeft">
                        Have a question or want to work together?
                    </AnimationOnScroll>

                    <AnimationOnScroll animateOnce animateIn="animate__bounceInLeft">
                        <form className="contact-form" onSubmit={submitForm}>
                            {success && (
                                <div className="alert alert-success bg-success contact-alert animated jackInTheBox">
                                    {success}
                                </div>
                            )}
                            {error && (
                                <div className="alert alert-danger bg-danger contact-alert animated jackInTheBox">
                                    {error}
                                </div>
                            )}

                            <input className="form-control" placeholder="Name" type="text" name="name" ref={nameRef} required />
                            <input className="form-control" placeholder="Enter email" type="email" name="email" ref={emailRef} required />
                            <textarea className="form-control" placeholder="Your Message" name="message" ref={messageRef} />
                            <div className="text-right">
                                <input className="btn btn-primary" type="submit" value="SUBMIT" />
                            </div>
                        </form>
                    </AnimationOnScroll>
                </div>
            </section>
        </>
    );
};

export default ContactSection;
