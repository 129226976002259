import React, { ReactElement } from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

import { IPortfolioItem } from '../../../../../types/text';

interface PortfolioModalProps {
    show: boolean;
    onHide: () => any;
    item: IPortfolioItem;
}

const PortfolioModal = (props: PortfolioModalProps): ReactElement => {
    const { show, onHide, item } = props;

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <div className="modal-content PortfolioSection">
                <div className="gallery-screenshots">
                    <Carousel className="gallery-carousel" indicators interval={null}>
                        {item.video && (
                            <Carousel.Item>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe title="video" className="embed-responsive-item" src={item.video} allowFullScreen />
                                </div>
                            </Carousel.Item>
                        )}
                        {item.images.map((image, index) => (
                            <Carousel.Item key={image}>
                                <img className="d-block w-100" src={image} alt={`${item.title}-${index}`} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <div className="gallery-body">
                    <div className="gallery-title">{item.title}</div>
                    <div className="gallery-caption">{item.caption}</div>
                    <div className="gallery-description">
                        <ReactMarkdown>
                            {item.description}
                        </ReactMarkdown>
                    </div>
                    <div className="row">
                        <div className="col text-left">
                            {item.link && (
                                <a href={item.link} target="_blank" rel="noreferrer noopener" className="gallery-link">Visit Project</a>
                            )}
                        </div>
                        <div className="col text-right">
                            <button type="button" onClick={(): void => onHide()} className="gallery-link">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PortfolioModal;
